import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { Auth0Provider } from '@auth0/auth0-react'

window.user = JSON.parse(localStorage.getItem('user'))
  ? JSON.parse(localStorage.getItem('user'))
  : null

createRoot(document.getElementById('root')).render(
  <Auth0Provider
    domain={process.env.REACT_APP_OKTA_DOMAIN}
    clientId={process.env.REACT_APP_OKTA_CLIENT_ID}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin + '/#/login/callback',
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
